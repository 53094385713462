<script>
export default {
  computed: {
    tier() {
      return () => import(`@/components/Themes/${this.$cookies.get('theme') ?? 'default'}/Loyalty/Reward/Tier`);
    }
  },
  methods: {},
};
</script>
<template>
  <component :is="tier" v-if="tier"></component>
</template>